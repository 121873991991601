<script>
import { mapActions } from 'vuex'

export default {
  name: 'SolutionsConfig',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    InputField: () => import('@/components/general/InputField')
  },

  data () {
    return {
      backUrl: { name: 'solutions.types' },
      selectedOfferId: null,
      rules: []
    }
  },

  created () {
    this.getEndRules()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsEndRules',
      'attemptUpdateSolutionsEndRules'
    ]),

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    submit () {
      this.getEndRules()
    },

    getEndRules () {
      this.setFetching(true)

      const id = this.$route.params.id

      this.attemptGetSolutionsEndRules(id).then(res => {
        this.rules = res.data.configuration.solutionsType
      }).finally(() => {
        this.setFetching(false)
      })
    },

    updateEndRules () {
      const form = {}

      form.configurations = {}
      form.configurations.solutionsTypes = {}

      this.rules.forEach(rule => {
        form.configurations.solutionsTypes[rule.alias] = Number(rule.minimumRequiredConclusion)
      })

      this.attemptUpdateSolutionsEndRules(form).then(() => {
        this.setFeedback({ message: this.$t('solutions.config:feedback') })
      }).finally(() => {
        this.getEndRules()
      })
    }
  }
}
</script>

<template>
  <div class="main-content solutions-create">
    <ContentHeader
      :title="$t('offer_config.create:header.title')"
      dark-theme
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.solution_types')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />

      <ActionBar slot="actionbar" />

      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          dark
          @click="submit(false)"
        />
      </template>
    </ContentHeader>

    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <p
            class="solutions-create-page-title"
            v-html="$t('offer_config.create:page.title')"
          />

          <p
            class="solutions-create-page-description"
            v-html="$t('offer_config.create:page.description')"
          />

          <Datatable :items="rules">
            <template slot="thead">
              <tr>
                <th class="th">
                  {{ $t('offer_config:datatable.header.1') }}
                </th>

                <th class="th text-center">
                  {{ $t('offer_config:datatable.header.2') }}
                </th>
              </tr>
            </template>

            <template
              slot="tbody"
              slot-scope="props"
            >
              <tr>
                <td class="td input-range">
                  <InputField
                    v-model="props.item.minimumRequiredConclusion"
                    :label="props.item.name"
                    class="slider"
                    type="range"
                    :min="0"
                    :max="100"
                  />
                </td>

                <td
                  class="td"
                  width="80"
                >
                  <InputField
                    v-model="props.item.minimumRequiredConclusion"
                    :max="100"
                    :min="0"
                    type="number"
                    has-percent
                  />
                </td>
              </tr>
            </template>
          </Datatable>

          <Action
            slot="button"
            primary
            fixed-width
            type="button"
            :text="$t('global:save')"
            @click="updateEndRules"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>

<style lang="scss" src="../assets/scss/Solution.scss"></style>

<style>
.datatable .td.input-range {
  padding-right: 24px;
}
.datatable .td .form-item.has-value.has-floating-label .form-label {
  font-size: 1.5em;
  text-transform: capitalize;
  font-weight: 500;
}
</style>
